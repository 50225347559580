import React from 'react';
import {
    getQueryPassword,
    getSessionPassword,
    setSessionPassword
} from '@mkitio/gatsby-theme-password-protect/src/utils/utils'
import { useLocation } from "@reach/router";
export const PasswordProtect = () => {
    const location = useLocation()
    return (
        <div>
            <p>Password Protect: {getSessionPassword()}</p>
            <p>Password Protect: {getQueryPassword(location)}</p>
        </div>
    );
};

export default PasswordProtect;
