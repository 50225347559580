import { ActionType, IAction, IState } from './types';

export const reducer: React.Reducer<IState, IAction> = (state, action) => {
  switch (action.type) {
    case ActionType.Unlock:
      return { ...state, isUnlocked: true }
    case ActionType.Lock:
      return { ...state, isUnlocked: false }
    case ActionType.ShowLoading:
      return { ...state, isLoading: true }
    case ActionType.HideLoading:
      return { ...state, isLoading: false }
    default:
      return state;
  }
}
