import React, { createContext, Dispatch, useReducer } from 'react';
import { IAction, IState } from './types';
import { reducer } from './reducers';
import usePasswordProtection from '../app/password/usePasswordProtection';

const initialState: IState = { isUnlocked: false, isLoading: true };

export const StateContext = createContext<{
  state: IState;
  dispatch: Dispatch<IAction>;
}>({
  state: initialState,
  dispatch: () => null
});

export interface IStateProviderProps {
  children: any
}

export const StateProvider = ({ children }: IStateProviderProps) => {
  const { locked } = usePasswordProtection();
  const initState: IState = { isUnlocked: !locked, isLoading: true };
  const [state, dispatch] = useReducer(reducer, initState)

  return (<StateContext.Provider value={{ state, dispatch }}>
    {children}
  </StateContext.Provider>);
};

export default StateProvider;
