/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import Layout from "./src/layout/layout";

export const wrapRootElement = ({ element }) => {
    return <Layout>{element}</Layout>;
};