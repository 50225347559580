import React from "react";
import UnlockProjectContainer from "../components/UnlockProjects/UnlockProjectContainer";
import FlexBox from "../components/FlexBox";

const Header = () => {
  return (
    <header>
      {/* <FlexBox justifyContent={"flex-end"}>
        <UnlockProjectContainer />
      </FlexBox> */}
    </header>
  );
};

export default Header;
