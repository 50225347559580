export interface IState {
  isUnlocked: boolean;
  isLoading: boolean;
}

export enum ActionType {
  Unlock = 'UNLOCK',
  Lock = 'LOCK',
  ShowLoading = "SHOWLOADING",
  HideLoading = "HIDELOADING",
}

export interface IAction {
  type: ActionType;
}
