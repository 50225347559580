import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core';



const theme: Theme = createMuiTheme({
    palette: {
        background: {
            default: "#FEFBFB"
        },
        text: {
            secondary: '#5F2756'
        }
    },
    typography: {
        fontFamily: "Lato",
        h1: {
            fontFamily: "Playfair Display",
            fontWeight: "600",
            color: "#5F2756"
        },
        h2: {
            fontFamily: "Playfair Display",
            fontWeight: "600",
            color: "#5F2756"
        },
        h6: {
            letterSpacing: "1.14pt",
            fontWeight: "bold",
            color: "#5F2756",
        },
    }
});

const portfolioTheme = responsiveFontSizes(theme);
export default portfolioTheme;
