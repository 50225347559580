/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import 'typeface-playfair-display';
import 'typeface-lato';
import Header from "./header";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import portfolioTheme from "./PortfolioTheme";
import StateProvider from "../state/StateProvider";
import { Box, CssBaseline } from "@material-ui/core";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={portfolioTheme}>
      <StateProvider>
        <CssBaseline />
        <Header />
        <main>{children}</main>
      </StateProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
