import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const usePasswordQuery = () => {
  const { sitePlugin } = useStaticQuery(
    graphql`
      {
        sitePlugin(name: {eq: "@mkitio/gatsby-theme-password-protect"}) {
          pluginOptions {
            password
          }
          name
        }
      }
    `
  )
  return sitePlugin.pluginOptions.password;
};

export default usePasswordQuery;
