import { useContext, useState } from 'react';
import { StateContext } from '../../state/StateProvider';
import { getSessionPassword, setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import usePasswordQuery from '../../queries/usePasswordQuery';
import { ActionType } from '../../state/types';
import useUrlPassword from './useUrlPassword';

export const usePasswordProtection = () => {
  const { dispatch } = useContext(StateContext);
  const sitePassword = usePasswordQuery();

  const [locked, setLocked] = useState<boolean>(() => {
    if (getSessionPassword() === sitePassword) return false;
    return true;
  });

  const unlock = (pass: string): boolean => {
    if (pass === sitePassword) {
      setSessionPassword(pass);
      setLocked(false);
      dispatch({ type: ActionType.Unlock });
      return true;
    }

    setSessionPassword("");
    dispatch({ type: ActionType.Lock })
    return false;
  }

  return { locked, unlock };
};

export default usePasswordProtection;
